.footer {
  background-color: #293646;
  padding-top: 40px;
  flex-shrink: 0;

  &--row {
    display: flex;
    align-items: center;

    &__border {
      padding-bottom: 22px;
      border-bottom: 1px solid rgba(255,255,255,0.2)
    }

    &__last {
      padding: 16px 0;
    }
  }

  &--column {
    display: flex !important;
    flex-direction: column;

    @media screen and (max-width: 575px) {
      display: flex !important;
      text-align: center;

      a {
        margin: 0 auto !important;
      }
    }
  }

  &--column-title {
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: 7px;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }

  &--column-link {
    opacity: 0.8;
    color: rgba(255,255,255,0.85);
    font-size: 0.875rem;
    line-height: 1.125rem;
    display: flex;
    margin-right: auto;
    margin-bottom: 8px;
    font-weight: 500;
  }

  &--content-row {
    display: flex !important;
    align-items: center;
  }

  &--content-column {
    display: flex;
    margin: 0 0 8px 0;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  &--text {
    opacity: 0.5;
    color: rgba(255,255,255,0.85);
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  &--link {
    color: inherit !important;
    text-decoration: underline;
  }

  &--divider {
    display: block;
    margin: 0 10px;
  }

  &--social-icon {
    margin: 0 12px;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    &--logo-holder {
      padding-top: 20px;
      text-align: center;
    }
  }
}
