.static-page {
  padding-top: 30px;
  padding-bottom: 70px;

  &--content-block {
    margin-bottom: 30px;

    &-text {
      margin-bottom: 8px;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
    }
  }

  .text-red {
    color: #FF0000;
  }

  .text-semibold {
    font-weight: 600;
  }
}
