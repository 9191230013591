.directory-entry {
  @media screen and (min-width: 1200px) {
    padding-left: 14px;
  }

  &--status {
    margin: 16px 0;
    padding: 12px;
    border: 1px solid #717171;
    border-radius: 8px;

    &.active {
      color: #1ab394;
      border-color: #1ab394;
      background-color: rgba(#1ab394, 0.05);
    }

    &.pending {
      color: #f89407;
      border-color: #f89407;
      background-color: rgba(#f89407, 0.05);
    }

    &.inactive {
      color: #ff0000;
      border-color: #ff0000;
      background-color: rgba(#ff0000, 0.05);
    }
  }

  &--opening-hours {
    padding-top: 12px;

    &__inputs {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  &--only-premium {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 25px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(4px);

    &__content {
      max-width: 348px;
      text-align: center;
    }

    &__link,
    &__text {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 700;
    }

    &__text {
      pointer-events: none;
      cursor: default;
    }

    &__link {
      cursor: pointer;
      margin-right: 8px;
      color: #F00;
    }

    &.small {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .my-antiques--section-container {
    margin: 0;
  }
}
