.united-kingdom-map {
  &.wigtownshire #GBDGY, // Wigtownshire
  &.west-lothian #GBWLN, // West Lothian
  &.west-dunbartonshire #GBDGY, // West Dunbartonshire
  &.sutherland #GBHLD, // Sutherland
  &.selkirkshire #GBSCB, // Selkirkshire
  &.south-lanarkshire #GBSLK, // South Lanarkshire
  &.south-ayrshire #GBSAY, // South Ayrshire
  &.shetland-islands #GBZET, // Shetland Islands
  &.scottish-borders  #GBSCB, // Scottish Borders
  &.roxburghshire #GBSCB, // Roxburghshire
  &.ross-shire #GBHLD, // Ross-shire
  &.renfrewshire #GBRFW, // Renfrewshire
  &.perthshire #GBPKN, // Perthshire
  &.peeblesshire #GBSCB, // Peeblesshire
  &.perth-and-kinross #GBPKN, // Perth and Kinross
  &.orkney #GBORK, // Orkney
  &.north-lanarkshire #GBNLK, // North Lanarkshire
  &.north-ayrshire #GBNAY, // North Ayrshire
  &.na-h-eileanan-siar #GBELS, // Na h-Eileanan Siar
  &.nairnshire #GBHLD, // Nairnshire
  &.midlothian #GBMLN, // Midlothian
  &.kirkcudbrightshire #GBDGY, // Kirkcudbrightshire
  &.kinross-shire #GBFIF, // Kinross-shire
  &.kincardineshire #GBABE, // Kincardineshire
  &.inverness-shire #GBHLD, // Inverness-shire
  &.inverclyde #GBIVC, // Inverclyde
  &.highland #GBHLD, // Highland
  &.city-of_glasgow #GBGLG, // City of Glasgow
  &.fife #GBFIF, // Fife
  &.falkirk #GBFAL, // Falkirk
  &.city-of-edinburgh #GBEDH, // City of Edinburgh
  &.east-renfrewshire #GBERW, // East Renfrewshire
  &.east-lothian-haddingtonshire #GBELN, // East Lothian (Haddingtonshire)
  &.east-dunbartonshire #GBDGY, // East Dunbartonshire
  &.east-ayrshire #GBEAY, // East Ayrshire
  &.city-of-dundee #GBDND, // City of Dundee
  &.dumfriesshire #GBDGY, // Dumfriesshire
  &.cromartyshire #GBHLD, // Cromartyshire
  &.clackmannanshire #GBSTG, // Clackmannanshire
  &.caithness #GBHLD, // Caithness
  &.bute #GBAGB, // Bute
  &.berwickshire #GBSCB, // Berwickshire
  &.banffshire #GBMRY, // Banffshire
  &.argyll #GBAGB, // Argyll
  &.angus-forfarshire #GBANS, // Angus (Forfarshire)
  &.city-of-aberdeen #GBABE, // City of Aberdeen
  &.aberdeenshire #GBABD, // Aberdeenshire
  &.west-glamorgan #GBNTL, // West Glamorgan
  &.vale-of-glamorgan #GBVGL, // Vale of Glamorgan
  &.torfaen #GBTOF, // Torfaen
  &.swansea #GBSWA, // Swansea
  &.south-glamorgan #GBVGL, // South Glamorgan
  &.rhondda-cynon-taf #GBRCT, // Rhondda Cynon Taf
  &.radnorshire #GBPOW, // Radnorshire
  &.pembrokeshire #GBPEM, // Pembrokeshire
  &.newport #GBNWP, // Newport
  &.neath-port-talbot #GBNTL, // Neath Port Talbot
  &.montgomeryshire #GBPOW, // Montgomeryshire
  &.monmouthshire #GBMON, // Monmouthshire
  &.mid-glamorgan #GBVGL, // Mid Glamorgan
  &.merthyr-tydfil #GBMTY, // Merthyr Tydfil
  &.merionethshire #GBGWN, // Merionethshire
  &.isle-of-anglesey #GBAGY, // Isle of Anglesey
  &.gwynedd #GBGWN, // Gwynedd
  &.gwent #GBBGW, // Gwent
  &.glamorgan #GBCMN, // Glamorgan
  &.flintshire #GBFLN, // Flintshire
  &.denbighshire #GBDEN, // Denbighshire
  &.conwy #GBCWY, // Ceredigion
  &.ceredigion #GBCGN, // Ceredigion
  &.carmarthenshire #GBCMN, // Carmarthenshire
  &.cardiff #GBCRF, // Cardiff
  &.cardiganshire #GBCGN, // Cardiganshire
  &.caernarfonshire #GBGWN, // Caerphilly
  &.caerphilly #GBCAY, // Caerphilly
  &.bridgend #GBBGE, // Bridgend
  &.brecknockshire #GBPOW, // Brecknockshire
  &.blaenau-went #GBBGW, // Blaenau Went
  &.anglesey #GBAGY, // Anglesey
  &.city-of-derry #GBDRY, // Londonderry
  &.londonderry #GBLMV, // Londonderry
  &.fermanagh #GBFER, // Fermanagh
  &.city-of-belfast #GBBFS, // City of Belfast
  &.armagh #GBARM, // Armagh
  &.yorkshire #GBERY, // Yorkshire
  &.west-suffolk #GBSFK, // Suffolk
  &.westmorland #GBCMA, // - Westmorland
  &.staffordshire #GBSTS, // - Staffordshire
  &.south-humberside #GBNLN, // South Humberside
  &.soke-of-peterborough #GBPTE, // Soke of Peterborough
  &.lincolnshire-parts-of-holland #GBLIN, // Lincolnshire
  &.lincolnshire-parts-of-kesteven #GBLIN, // Lincolnshire
  &.lincolnshire-parts-of-lindsey #GBLIN, // Lincolnshire
  &.isle-of-wight #GBIOW, // Isle of Wight
  &.isle-of-ely #GBCAM, // Isle of Ely
  &.huntingdonshire #GBCAM, // Huntingdonshire
  &.huntingdon-and-peterborough #GBPTE, // Huntingdon and Peterborough
  &.east-suffolk #GBSFK, // Suffolk
  &.cumberland #GBCMA, // - Cumberland
  &.cheshire #GBCHW, // - Cheshire (Cheshire West and Chester)
  &.cheshire #GBCHE, // Cheshire (Cheshire East)
  &.herefordshire #GBHEF, // - Herefordshire
  &.gloucestershire #GBGLS, // - Gloucestershire
  &.northumberland #GBNBL, // - Northumberland
  &.cumbria #GBCMA, // - Cumbria
  &.durham #GBDUR, // - Durham
  &.lincolnshire #GBLIN, // Lincolnshire
  &.norfolk #GBNFK, // Norfolk
  &.essex #GBESS, // Essex
  &.kent #GBKEN, // Kent
  &.east-sussex #GBESX, // East Sussex
  &.city-of-brighton-and-hove #GBBNH, // City of Brighton and Hove
  &.hampshire #GBHAM, // Hampshire
  &.dorset #GBDOR, // Dorset
  &.west-sussex #GBWSX, // West Sussex
  &.devon #GBDEV, // Devon
  &.cornwall #GBCON, // Cornwall
  &.city-of-bristol #GBBST, // City of Bristol (Bristol)
  &.lancashire #GBLAN, // Lancashire
  &.hertfordshire #GBHRT, // Hertfordshire
  &.bedfordshire #GBCBF, // Bedfordshire (Central Bedfordshire, Bedford)
  &.bedfordshire #GBBDF,
  &.buckinghamshire #GBBKM, // Buckinghamshire
  &.rutland #GBRUT, // Rutland
  &.nottinghamshire #GBNTT, // Nottinghamshire
  &.northamptonshire #GBNTH, // Northamptonshire
  &.oxfordshire #GBOXF, // Oxfordshire
  &.berkshire #GBWBK, // Berkshire (West Berkshire)
  &.north-yorkshire #GBNYK, // North Yorkshire
  &.somerset #GBSOM, // Somerset
  &.surrey #GBSRY, // Surrey
  &.worcestershire #GBWOR, // Worcestershire
  &.cambridgeshire #GBCAM, // Cambridgeshire
  &.yorkshire-east-riding #GBERY, // Yorkshire (East Riding of Yorkshire)
  &.yorkshire-north-riding #GBNYK, // Yorkshire (North Yorkshire)
  &.yorkshire-west-riding #GBDNC, // Yorkshire (East Riding of Yorkshire)
  &.derbyshire #GBDBY, // Derbyshire
  &.shropshire #GBSHR, // Shropshire
  &.suffolk #GBSFK, // Suffolk
  &.warwickshire #GBWAR, // Warwickshire
  &.wiltshire #GBWIL, // Wiltshire
  &.yorkshire-west-riding #GBWYK, // Yorkshire (West Yorkshire)
  &.antrim #GBANT, // Antrim
  &.down #GBDOW, // Down
  &.lanarkshire #GBNLK, // Lanarkshire
  &.lanarkshire #GBSLK, // Lanarkshire
  &.moray-elginshire #GBMRY, // Moray (Elginshire)
  &.stirlingshire #GBSTG, // Stirlingshire
  &.stirlingshire #GBFAL, // Stirlingshire
  &.conwy #GBCWY, // Conwy
  &.west-glamorgan #GBNTL, // West Glamorgan
  &.west-glamorgan #GBSWA, // West Glamorgan
  &.mid-glamorgan #GBVGL, // Mid Glamorgan
  &.wrexham #GBWRX, // Wrexham
  &.powys #GBPOW, // Powys
  {
    fill: #004f57;
  }

  &.shetland-zetland {
    #GBZET,
    #GBSHI {
      fill: #004f57;
    }
  }

  &.ross-and-cromarty {
    #GBELS,
    #GBHLD {
      fill: #004f57;
    }
  }

  &.dunbartonshire-dumbarton {
    #GBDGY,
    #GBWLN {
      fill: #004f57;
    }
  }

  &.ayrshire {
    #GBEAY,
    #GBSAY {
      fill: #004f57;
    }
  }

  &.dyfed {
    #GBPEM,
    #GBCMN,
    #GBCGN {
      fill: #004f57;
    }
  }

  &.clwyd {
    #GBDEN,
    #GBCWY {
      fill: #004f57;
    }
  }

  &.tyrone {
    #GBMFT,
    #GBCKT {
      fill: #004f57;
    }
  } // Tyrone

  &.west-midlands {
    #GBSHR,
    #GBHEF,
    #GBWOR,
    #GBTFW,
    #GBWLV,
    #GBDUD,
    #GBSTS {
      fill: #004f57;
    }
  }

  &.middlesex {
    #GBHNS,
    #GBHRW,
    #GBWSM,
    #GBCMD,
    #GBENF,
    #GBCMD,
    #GBEAL,
    #GBBEN,
    #GBBNE,
    #GBHRY,
    #GBHMF {
      fill: #004f57;
    }
  }

  &.north-humberside {
    #GBERY,
    #GBKHL {
      fill: #004f57;
    }
  }

  &.leicestershire {
    #GBLEC,
    #GBLCE {
      fill: #004f57;
    }
  }

  &.humberside {
    #GBERY,
    #GBNLN {
      fill: #004f57;
    }
  }

  &.sussex {
    #GBWSX,
    #GBESX {
      fill: #004f57;
    }
  }

  // South Yorkshire (West Yorkshire)
  &.south-yorkshire {
    #GBNYK,
    #GBERY,
    #GBYOR {
      fill: #004f57;
    }
  }

  // Merseyside
  &.merseyside {
    #GBSHN,
    #GBSFT,
    #GBLIV,
    #GBKWL,
    #GBWRL {
      fill: #004f57;
    }
  }

  // West Yorkshire
  &.west-yorkshire {
    #GBBRD,
    #GBLDS,
    #GBWKF,
    #GBKIR,
    #GBCLD {
      fill: #004f57;
    }
  }

  // Avon
  &.avon {
    #GBNSM,
    #GBSGC,
    #GBBST,
    #GBBAS {
      fill: #004f57;
    }
  }

  // Greater Manchester
  &.greater-manchester {
    #GBMAN,
    #GBSKP,
    #GBTAM,
    #GBOLD,
    #GBRCH,
    #GBBUR,
    #GBBOL,
    #GBWGN,
    #GBSLF,
    #GBTRF {
      fill: #004f57;
    }
  }

  // Tyne and Wear
  &.tyne-and-wear {
    #GBNET,
    #GBNTY,
    #GBSTY,
    #GBSND,
    #GBGAT {
      fill: #004f57;
    }
  }

  // London (Lambeth, Southwark, Hillingdon)
  &.london,
  &.greater-london,
  &.city-of-london {
    #GBLBH,
    #GBSWK,
    #GBHIL,
    #GBHRW,
    #GBBNE,
    #GBENF,
    #GBHNS,
    #GBRIC,
    #GBKTT,
    #GBSTN,
    #GBCRY,
    #GBBRY,
    #GBBEX,
    #GBHAV,
    #GBRDB,
    #GBWFT,
    #GBEAL,
    #GBMRT,
    #GBWND,
    #GBLEW,
    #GBGRE,
    #GBBDG,
    #GBNWM,
    #GBBEN,
    #GBHRY,
    #GBTWH,
    #GBHMF,
    #GBHCK,
    #GBCMD,
    #GBKEC,
    #GBWSM,
    #GBISL {
      fill: #004f57;
    }
  }
}

