.not-found {
  &--wrapper {
    height: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.error-boundary {
      margin-top: 0;
      height: auto;
      align-items: flex-start;
      padding: 20vh 0;
    }
  }

  &--container {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 16px;
    align-items: center;
    justify-content: center;
    background-color: #F7F7F7;
    padding: 32px 200px ;

    @media screen and (max-width: 800px) {
      padding: 32px 50px;
      width: 100%;

    }

    @media screen and (max-width: 500px) {
      padding: 32px 10px;
      width: 100%;

      .header--mobile-search {
        padding: 0 10px;
      }
    }

    .header--mobile-search-container--search {
      width: 100%;
    }

    .header--search, .header--mobile-search {
      .ant-input-wrapper.ant-input-group {
        border: 1px solid #438C93 !important;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        padding: 0 8px;
      }

      .ant-input {
        border: none;
        padding-left: 0;
      }

      .ant-input-group-addon {
        border: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 40px;
        padding: 0;
        background-color: #FFFFFF;
      }

      .ant-input.ant-input-lg ~ .ant-input-group-addon {
        display: none;
      }
    }
  }

  &--title {
    color: #FF0000;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  &--text {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #3D3D3D;

    @media screen and (max-width: 500px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &--go-home-button span {
    color: #FF0000;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  &--go-home-container {
    text-align: center;
  }

  &--divider {
    margin: 16px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242424;
    font-size: 18px;
    line-height: 16px;

    &::before, &::after {
      content: " ";
      width: 120px;
      height: 2px;
      background-color: #EBEBEB;
    }
  }

  &--divider-text {
    margin: 0 20px;
  }
}
