.dlz-form {
  &--bordered-section {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .dlz-form--section-title {
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
    }
  }

  &--autocomplete {
    .ant-select-selector {
      padding: 0 !important;
    }

    .ant-select-selection-search {
      left: 0 !important;
    }
  }

  &--tooltip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-left: 16px;
    background-color: #004F57;
    border-radius: 50%;
    color: #ffffff;
    font-size: 10px;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;
    line-height: 1;

    & > label,
    & > label.ant-form-item-required:before {
      color: rgba(0, 0, 0, 0.5);
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .ant-form-item-required:before {
    order: 2;
    margin-left: 2px;
  }

  .ant-form-item-control-input-content {
    border-bottom: 1px solid #7f7f7f;
  }

  .ant-input {
    border-width: 0;
    padding: 0;
    min-height: 32px;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
  }

  .ant-input-textarea-show-count:after {
    font-size: 12px;
  }

  .ant-input-textarea-status-error:after {
    color: #ff4d4f;
  }

  .ant-input-group-addon {
    border: none;
    background-color: transparent;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
    border: none;
  }

  .ant-select-multiple {
    .ant-select-selection-placeholder {
      left: 0;
    }

    .ant-select-selection-search-mirror {
      min-height: 32px;
    }
  }

  .ant-picker {
    border-color: transparent !important;
    border-radius: 0;
  }

  .dlz-form--switch {
    .ant-form-item-row {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
    }

    .ant-form-item-control {
      max-width: 50px;
    }

    .ant-form-item-control-input-content {
      border: none;
    }

    .ant-form-item-label label {
      position: relative;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-transform: none;

      span {
        margin-left: 24px;
        color: #999999;
        font-size: 14px;
      }
    }
  }

  .react-tel-input {
    border-bottom: none;

    .form-control {
      height: 32px;
    }
  }

  .uk-county-select {
    margin-top: 0;
  }

  .my-antiques--title-section {
    display: none;
  }

  .website-social-media__row {
    width: 100%;

    .ant-form-item,
    .ant-form-item-row {
      width: 100%;
      margin: 0;
    }

    .ant-form-item-label > label,
    .ant-form-item-label > label.ant-form-item-required:before {
      font-size: 16px;
    }
  }

  .website-social-media__field .input-field {
    border: none;
  }
}
