.images-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .dealer-details--upload {
    width: 280px !important;
    height: 280px !important;
  }

  @media screen and (max-width: 360px) {
    .dealer-details--upload {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}
