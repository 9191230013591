.dlz-table {
  &--status-wrapper {
    display: inline-flex;
    align-items: center;
  }

  &--status-dot {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 3px;

    &__created {
      background-color: #eb9838;
    }

    &__paid {
      background-color: #52c41a;
    }

    &__cancelled,
    &__failed {
      background-color: #ff0000;
    }
  }
}
