.pointer {
  cursor: pointer;
}

.h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: $h1-color;
}

.h2 {
  font-size: 1.625rem;
  line-height: 2.0625rem;
  font-weight: 700;
  color: $h2-color;
}

.h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: $h3-color;
}

.h1, .h2, .h3 {
  .highlight {
    color: #FF0000;
  }
}

.text-center {
  text-align: center !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-primary {
  color: #004F57 !important;
}

.text-red {
  color: #FF0000 !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-32 {
  font-size: 32px !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
