@import "../_common/variables";

.header {
  height: 115px;
  min-height: 115px;
  max-height: 115px;
  background-color: #fff;
  display: flex;
  padding: 20px 0 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-shrink: 0;

  &--mobile-search-logo-container {
    text-align: center;
    display: block;
    margin-bottom: 16px;

    &.hidden {
      display: none;
      margin-bottom: 0;
    }
  }

  &--timestamp {
    display: none;
  }

  &--hidden-categories-links {
    display: none;
  }

  &--burger-menu-container {
    display: none;

    @media screen and (max-width: $xl) {
      display: flex;
    }
  }

  &--search-icon {
    font-size: 28px;
    color: #438c93;
    align-self: center;
    margin-right: 15px;
  }

  &--dropdown-menu {
    display: flex;
    flex-direction: row;
    padding: 0 20px 10px 20px !important;
    max-height: 500px;
  }

  &--dropdown-menu-title-category {
    color: #ff0000;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }

  &--dropdown-menu-item {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    margin-right: 50px;
    margin-top: 5px;
  }

  &--dropdown-menu-category-container-all-categories,
  &--dropdown-menu-category-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 400px;
  }

  &--dropdown-menu-container-first-column {
    display: flex;
    flex-direction: column;
  }

  &--dropdown-overlay {
    width: 100%;
  }

  &--dropdown-icon {
    margin-left: 10px;
    font-size: 15px !important;
  }

  &--logo-row {
    max-height: 40px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-bottom: 20px;
    }

    .megamenu {
      position: relative;
      width: 100%;
    }

    .megamenu-dropdown-wrapper {
      width: 100%;
      margin: 0 auto;
      max-width: 1490px;
      height: 491px;
      display: none;
      position: absolute;
      padding: 25px;
      background: #fff;
      z-index: 2000;

      &.shown {
        display: flex;
        max-height: 500px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
          0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      }

      &.hidden {
        display: none;
      }

      &.directory {
        height: 270px;
      }
    }

    .dropdown-category {
      max-height: 450px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &--title {
        font-size: 16px;
        font-weight: 700;
        color: $primary-bg-color;
        margin: 10px 0 5px;
      }

      .dropdown-item {
        color: #000;
        font-size: 16px;
        margin-right: 50px;
        margin-top: 5px;
      }
    }
  }

  &--logo {
    flex-shrink: 0;
    margin-right: 30px;
    display: flex;
    align-items: center;

    img {
      width: 220px;
      height: auto;
    }

    @media screen and (max-width: 359px) {
      margin-right: 0;
    }
  }

  &--mobile-search-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .ant-select {
      margin: 0 !important;
    }

    &--search {
      width: 90%;
    }

    &--cancel-btn {
      width: 65px;
      height: 40px;
      background: #858585;
      color: #ffffff;
      border-radius: 4px;
      line-height: 40px;
      padding: 0 12px;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 600;
    }

    &.hidden {
      display: none;
    }
  }

  &--mobile-search {
    position: relative;
    display: none;
    width: 100%;
    margin: 0 auto;

    .ant-select-auto-complete {
      width: 100%;
    }

    .ant-input {
      height: 40px !important;
      border-color: #438c93 !important;
      box-shadow: none !important;
    }

    .ant-input-group-addon .ant-btn {
      background-color: #438c93;
      border: none;
      border-radius: 0 4px 4px 0 !important;
    }

    @media screen and (max-width: $xl) {
      display: block;
    }
  }

  &--search {
    width: 100%;
    position: relative;

    .ant-select-auto-complete {
      width: 100%;
    }

    .ant-input {
      height: 40px !important;
      border-color: #438c93 !important;
      box-shadow: none !important;
    }

    .ant-input-group-addon .ant-btn {
      background-color: #438c93;
      border: none;
      border-radius: 0 4px 4px 0 !important;
    }

    .ant-btn-loading-icon {
      width: 18px;
      height: 18px;
    }
  }

  &--search-result-item {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__with-type {
      display: grid;
      grid-template-columns: calc(100% - 96px - 16px) 96px;
      grid-gap: 16px;

      .header--search-result-item-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__item {
      display: grid;
      grid-template-columns: 96px calc(100% - 96px - 16px);
      grid-gap: 16px;

      .header--search-result-item-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .header--search-result-item-image {
        height: 96px;
        width: 96px;
        object-fit: contain;
        border: 2px solid #ffffff;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
      }

      .header--search-result-item-title {
        margin: 0;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }

      .header--search-result-item-description {
        font-size: 16px;
        font-weight: 400;
        color: #999a9e;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }

      .header--search-result-item-status {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &--search-result-type {
    color: #fff;
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;

    &__category {
      background-color: #cb6157;
    }
    &__period {
      background-color: #3a98d9;
    }
    &__material {
      background-color: #c0c0c0;
    }
    &__origin {
      background-color: #7ad7c4;
    }
    &__artisan {
      background-color: #f7a327;
    }
    &__dealer {
      background-color: #990099;
    }
    &__homeandliving {
      background-color: #4b0082;
    }
  }

  &--icon {
    cursor: pointer;
  }

  &--right-block {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 24px;
  }

  &--heart-icon {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: #000000;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--favorites-and-notifications {
    padding: 0 16px;
    margin-right: 24px;

    display: flex;
    grid-gap: 16px;

    border-left: 1px solid rgba(0, 0, 0, 0.25);
    border-right: 1px solid rgba(0, 0, 0, 0.25);
  }

  &--bell-icon {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    cursor: pointer;
    color: #000000;

    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: color;

    svg {
      width: 24px;
      height: 24px;
    }

    &__badge::after {
      content: attr(data-count);
      position: absolute;
      top: 0;
      right: 0;
      border: 2px solid #ffffff;
      width: 12px;
      height: 12px;
      background: #ff3029;
      border-radius: 50%;
    }

    &:hover {
      color: #ff3029;
    }
  }

  &--auth-control {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
      align-items: center;
    }
  }

  &--auth-name {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 1rem;
    margin-left: 8px;
    margin-bottom: 0;
    line-height: 18px;
    width: 80px;
    cursor: pointer;

    @media screen and (max-width: $xl) {
      width: 40%;
    }
  }

  &--auth-link {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25px;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #438c93;
    width: 116px;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #438c93;
    }

    &:hover,
    &:focus {
      background: #ffffff;
      border: 1px solid #438c93;
    }
  }

  &--auth-button {
    width: 116px;
    height: 40px;
    margin-left: 24px;
    font-size: 1rem !important;

    &--logout {
      width: 90px;
      background: #ffffff;

      span {
        color: #ff0000;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
      }

      @media screen and (max-width: 359px) {
        font-size: 16px;
      }
    }
  }

  &--top-bar-category-container {
    display: inline-block;
  }

  &--top-bar-category-container:not(:first-child) {
    margin-left: 20px;

    @media screen and (max-width: 1100px) {
      margin-left: 11px;
    }
  }

  &--link,
  &--all-antiques-link {
    p { 
      margin: 0; 
    }

    display: inline-block;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;

    &:hover {
      border-bottom: 2px solid #ff0000;
    }
  }

  &--link {
    &--new {
      color: #ff0000;
      position: relative;
      top: -4px;
      font-size: 12px;
    }

    &.mobile {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75%;

      &:hover {
        border-bottom: none;
      }
    }

    &.drawer {
      color: #ff0000 !important;
    }
  }

  &--mobile-menu {
    display: flex;
    flex-direction: column;

    .ant-menu {
      background: #fff !important;
      padding: 0 !important;

      .ant-menu-submenu {
        border-bottom: 1px solid #d8d8d8 !important;

        .ant-menu-item:not(:last-child) {
          border-bottom: 1px solid #d8d8d8 !important;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .ant-menu-item:not(:last-child) {
        border-bottom: 1px solid #d8d8d8 !important;
      }
    }
  }

  &--menu {
    .ant-menu-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--mobile-menu-fav-and-notifications {
    display: flex;
    grid-gap: 18px;
    margin: 12px 0;
    width: 100%;

    .header--bell-icon,
    .header--heart-icon {
      padding: 0;
      margin: 0;
      border: unset;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  .header--mobile-menu-button {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    width: 40px !important;
    justify-content: center;
    align-items: center;
  }

  &--mobile-menu-icon {
    display: block;
    width: 30px;
    height: 4px;
    margin-bottom: 4px;
    position: relative;
    background: #fff;
    border-radius: 2px;
    flex-shrink: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--show-more {
    display: block;
    padding: 0 15px;
    margin: 10px 0;
    font-size: 18px;
    cursor: pointer;
    color: #ff0000;
  }

  &--show-more:hover {
    color: #000000;
    text-decoration: underline;
  }

  &--mobile-menu-footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 10px 0 24px;
  }

  &--mobile-menu-footer-btn {
    width: 100%;
  }

  &--socials-group {
    display: grid;
    grid-template-columns: repeat(5, 32px);
    grid-gap: 16px;
    align-items: center;
    margin-right: 24px;

    height: 100%;
  }

  &--social-icon {
    width: 32px !important;
    height: 32px !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.search-result-active-item {
  background: #eceef4;
  cursor: pointer;
}

.sms-notification-modal {
  .ant-modal-body {
    display: flex;

    p {
      width: 80%;
      margin: 20px auto;
      font-size: 20px;
    }
  }

  &__delivery {
    width: 800px !important;

    p {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: $xl) {
  .header {
    height: 124px;
    min-height: 124px;
    max-height: 124px;

    &--search-result-row {
      .ant-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--search-type {
        font-size: 12px;
      }
    }

    &--logo-row {
      justify-content: space-between;
      margin-bottom: 0 !important;
      display: none;
    }

    &--right-block {
      margin-left: 0;
    }

    &--auth-control {
      padding-left: 0;
      margin-left: 0;
      border-left: none;
      margin-bottom: 15px;
    }

    &--search {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $md) {
  .header {
    &--dropdown-menu-category-container-all-categories {
      max-height: 530px;
    }

    &--dropdown-overlay {
      width: 750px;
    }

    &--dropdown-menu {
      max-height: 750px;
    }
  }
}

.ant-select-item:has(.header--search-result-item) {
  padding: 0 !important;
}
