.video-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .la-video__uploader,
  .la-video__preview {
    width: 280px !important;
    position: relative;
    margin-right: 10px !important;
    margin-top: 10px !important;
    border-radius: 4px;
    background-color: #F7F7F7;
    border: 1px dashed #d7d7d7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .la-video__preview {
    height: 160px !important;
  }

  .la-video__uploader {
    height: 96px !important;
  }

  .la-video__container {
    width: 100%;
    max-width: 280px;
    height: 160px;
  }

  .la-video__divider {
    margin: 3px 0;
  }

  .la-video__btn {
    margin: 5px 0;
  }

  .la-video__input-group {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }

  .ant-input {
    padding: 3px 11px;
    border-width: 1px;
  }

  @media screen and (max-width: 360px) {
    .la-video__uploader,
    .la-video__preview {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}
