.flex {
  display: flex !important;
  align-items: center !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.no-shrink {
  flex-shrink: 0 !important;
}

.align-center {
  align-items: center !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.flex-gap-1 {
  gap: 4px !important;
}

.flex-gap-2 {
  gap: 8px !important;
}

.display-none {
  display: none !important;
}

.sm-visible {
  display: none !important;
}

.md-visible {
  display: none !important;
}

.lg-visible {
  display: none !important;
}

.lg-view-visible {
  display: none !important;
}

.lg-view-hidden {
  display: block !important;
}

@media screen and (max-width: $xl) {
  .lg-view-visible {
    display: block !important;
  }

  .lg-view-hidden {
    display: none !important;
  }

  .xl-visible {
    display: flex !important;
  }

  .xl-hidden {
    display: none !important;
  }
}


@media screen and (max-width: 991px) {
  .md-visible {
    display: flex !important;
  }

  .md-hidden {
    display: none !important;
  }
}

@media screen and (max-width: $sm) {
  .sm-hidden {
    display: none !important;
  }

  .sm-visible {
    display: flex !important;
  }
}

@media screen and (max-width: $xs) {
  .xs-hidden {
    display: none !important;
  }

  .xs-visible {
    display: flex !important;
  }
}
