.page-fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 115px);

  @media screen and (max-width: 1200px) {
    height: calc(100vh - 124px);
  }
}
