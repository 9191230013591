.ant-btn {
  border-radius: 4px !important;
  line-height: 1 !important;

  span {
    color: white;
  }
}

.ant-btn-ghost {
  span {
    color: #FF0000;
  }
}

.ant-btn-default {
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.ant-btn-background-ghost {
  border: 1px solid #FF0000 !important;
  color: #FF0000 !important;
}

.ant-modal-header {
  border: none !important;
}

.ant-modal-body {
  padding: 0 !important;
}


.ant-menu {
  border-right: none !important;
  background-color: #F7F7F7 !important;
  padding: 18px 0 72px 0 !important;
  max-width: 263px !important;
}

.ant-menu-item {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  height: 36px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;

  svg {
    margin: 0 12px 0 0;
  }
}

.ant-menu-submenu-title {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  height: 36px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;

  svg {
    margin: 0 12px 0 0;
  }
}

.ant-menu-item::after {
  border-right: none !important;
}

.ant-menu-item-selected,
.ant-menu-item-active,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: rgba(0, 79, 87, 0.1) !important;
}

.ant-pagination-item {
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 25px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 40px !important;
  height: 40px !important;
}

.ant-pagination-item,
.ant-pagination-item-active,
.ant-pagination-item-link {
  border-radius: 50% !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item,
.ant-pagination-item-active {
  border-radius: 50% !important;
  margin: 0 4px 0 0 !important;
}

.ant-pagination li:last-of-type {
  margin: 0 0 0 4px !important;
}

.ant-pagination-item-active {
  border-radius: 50% !important;
  background: $primary-bg-color !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-item,
.ant-pagination-prev, .ant-pagination-next {
  @media screen and (max-width: 575px) {
    min-width: 35px !important;
    height: 35px !important;
    font-size: 18px !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 50% !important;
    padding: 5px 0 !important;

    a {
      padding: 2px !important;
    }

    &.ant-pagination-item-active {
      border: none !important;
      padding-top: 6px !important;
    }
  }
}

.ant-pagination-prev {
  margin-right: 5px !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  min-width: 40px !important;
  height: 40px !important;

  @media screen and (max-width: 575px) {
    a {
      line-height: 26px !important;
    }
  }
}

.ant-select:not(.ant-select-disabled):hover, .ant-select-selector {
  border-color: #F7F7F7 !important;
  box-shadow: none !important;
}

.ant-input:hover, .ant-picker:hover {
  border-color: #F1F1F1 !important;
}

.ant-tabs-nav {
  color: rgba(0, 0, 0, 0.5);
}

.ant-input:focus, .ant-picker-focused {
  border-color: #F1F1F1 !important;
  box-shadow: none !important;
}

.ant-slider-tooltip {
  padding-bottom: 0 !important;
  z-index: unset !important; // Autofill z-index with value of 1060

  .ant-tooltip-inner {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    padding: 0;
    min-height: 0;
    box-shadow: none;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.antd-pro-charts-pie-percent,
.antd-pro-charts-pie-value {
  display: none;
}

.antd-pro-charts-pie-pie {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ant-tag {
  border: 1px solid #F1F1F1 !important;
  background-color: #F7F7F7 !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;

  a {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.ant-input-affix-wrapper {
  border: none !important;

  &:hover,
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.ant-input-affix-wrapper-focused {
  border: none !important;
  box-shadow: none !important;
}

.ant-breadcrumb {
  text-transform: uppercase;
}

.ant-input-password {
  border-bottom: 1px solid #E5E5E5 !important;
  padding: 2.4px 11px 2.4px 0 !important;
}

.ant-input-password:hover {
  border-bottom: 1px solid #E5E5E5 !important;
  padding: 2.4px 11px 2.4px 0 !important;
}

.ant-dropdown {
  background-color: rgb(255, 255, 255);
  overflow: auto;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow-y: hidden;
}

.ant-dropdown-menu {
  box-shadow: none !important;
}

.ant-drawer-content-wrapper {
  @media screen and (max-width: 991px) {
    width: 320px !important;
  }

  @media screen and (max-width: 500px) {
    width: 300px !important;
  }

  @media screen and (max-width: 359px) {
    width: 250px !important;

    .ant-drawer-body {
      padding: 24px 12px;
    }
  }
}

.ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.ant-col-lg-24 {
  width: 100%;
}

.ant-modal-confirm {
  .ant-modal-content {
    width: 100%;
    padding: 20px;
  }

  .ant-modal-confirm-content p {
    margin: 0;
  }

  .ant-btn:not(.ant-modal-confirm__ok-button) {
    background-color: #FFFFFF;

    span {
      color: #FF0000;
    }
  }
}

.ant-row {
  margin-bottom: 15px;
}

.ant-message-notice-content {
  max-width: 900px;
}
