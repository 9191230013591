body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
  font-display: swap;
  line-height: 1.5715;
}

h1 {
  font-family: "Source Sans Pro", sans-serif;
  font-display: swap;
  line-height: 1.5715;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper {
  min-height: 100vh;
  position: relative;

  &--content {
    min-height: 100vh;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
