.page-container {
  width: 100%;
  max-width: 1490px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    .ant-breadcrumb {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.read-more-button {
  color: #FF0000;
  font-weight: 600;
  cursor: pointer;
}

.read-more-text {
  margin-right: 5px;
}

.content-block-text {
  margin-bottom: 16px;
  opacity: 0.8;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
}

.pagination-arrow-left,
.pagination-arrow-right {
  padding: 5px 0 0 0;
  color: #004F57 !important;
  font-size: 16px !important;
  line-height: 30px;
  font-family: "Lucida Grande", sans-serif;
}

.info-button {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dot-separator {
  color: #FF0000;
}

.red-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 75px 0 0 0;

  &_centered {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.input-error {
  color: $primary-bg-color;
  font-size: 12px;
}

.input-warning {
  color: #faad14;
  font-size: 12px;
  white-space: pre-wrap;
}

.input-entered-text-counter {
  color: $secondary-fg-color;
  font-size: 12px;
  text-align: right;
}

.ql-align-right {
  text-align: right;
  align-self: flex-end;
}

.ql-align-center {
  text-align: center;
  margin: 0 auto;
}

.ql-video {
  max-width: 300px;
}

.ql-size-small {
  font-size: 12px;
}

.ql-size-large {
  font-size: 18px;
}

.ql-size-huge {
  font-size: 21px;
}

.rich-text-editor {
  &--disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.tab-title {
  font-size: 18px;
  font-weight: 600;
}

.label {
  font-weight: 600;
  font-size: 14px;
  color: #7E7F7D;
}

.drag-sorting-table {
  overflow: auto;

  tr.drop-over-downward td {
    border-bottom: 2px dashed $secondary-fg-color;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed $secondary-fg-color;
  }

  &--image {
    width: 90px;
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  &--date {
    text-transform: uppercase;
    opacity: .5;
    font-size: 14px;
    line-height: 14px;
  }

  &--word-bleak {
    word-break: break-word;
  }
}

.ant-select-item-option {
  border-bottom: 1px solid #FAFAFA;
}

.ant-select-item-option-selected {
  background-color: #F2F2F2 !important;
}

.dropdown-drawer {
  &__header {
    padding: 24px 24px 20px;
  }

  &.category-filter {
    .ant-drawer-content-wrapper {
      min-height: 363px !important;
    }
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
    min-height: 240px !important;

    .ant-drawer-content {
      border-radius: 4px 4px 0 0;

      .ant-drawer-body {
        padding: 0 !important;

        .ant-menu {
          max-width: none !important;
          padding: 0 !important;
          background-color: #FFFFFF !important;

          .ant-menu-item {
            height: 36.5px !important;
            color: #717171 !important;
            margin: 0 !important;
          }

          .ant-menu-item-selected {
            color: #000000 !important;
            font-weight: 600 !important;
          }
        }
      }
    }
  }
}

.modal-images-slider {
  .ant-modal-body {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-footer {
    .ant-btn:first-child {
      background-color: #FFFFFF;

      span {
        color: #ff0000;
      }
    }
  }
}

.image-cropping-modal {
  width: auto !important;
  max-width: 1210px !important;
  overflow: hidden;

  &__btn-group {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-gap: 15px;
    justify-content: center;
    margin: 10px 0;
  }

  .ant-modal-body {
    padding: 40px 20px 0 !important;

    .crop-image-container {
      position: relative;
      width: 100%;

      .tooltip-container {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 45%;
        z-index: 1000;

        &.hidden {
          display: none;
        }

        &--tooltip {
          display: flex;
          align-items: center;
          padding: 6px 9px;
          background-color: #FFFFFF;
          border-radius: 4px;

          &--icon {
            margin-top: 5px;
          }

          &--text {
            margin-left: 6px;
          }
        }
      }
    }

    .cr-slider-wrap {
      margin: 10px auto;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.callback-modal {
  top: 5px !important;

  &--body {
    margin-top: 30px;

    &-header {
      height: 56px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }

    &-main {
      padding: 30px 30px 50px 30px;
      display: flex;
      flex-direction: column;

      h2 {
        align-self: center;
      }

      form {
        display: flex;
        flex-direction: column;
      }

      &-input {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;
        margin-bottom: 20px !important;
      }

      .react-tel-input {
        margin: 0 0 10px 0;

        .form-control {
          width: 100%;
        }
      }

      &-error {
        margin: -18px 0 10px 0;
        color: $primary-bg-color;
        font-size: 12px;
      }

      &-submit {
        align-self: center;
      }
    }
  }

  .input-field {
    margin: 0 0 15px 0;
  }
}

.react-images__view {
  img {
    max-height: 90vh;
    max-width: 90%;
  }
}

.recipient-modal {
  .ant-modal-body {
    min-height: 250px;
    max-height: 80%;
    padding: 50px 20px 20px 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .recipient-label,
  .message-label,
  .cc-label {
    display: flex;
    width: 100%;

    .ant-input {
      flex: 1 1 auto;
    }
  }

  .preview-sharing-error {
    margin: 10px 0 0;
    font-size: 12px;
    color: #ff0000;
  }

  .recipient-label {
    .ant-input {
      margin-left: 20px;
    }
  }

  .message-label {
    margin-top: 15px;

    .ant-input {
      margin-left: 15px;
    }
  }

  .cc-label {
    margin-top: 15px;

    .ant-input {
      margin-left: 55px;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }

  &--text {
    flex: 1 1 auto;
    white-space: nowrap;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    width: 90% !important;
    padding: 20px;

    &--text {
      flex: unset !important;
      white-space: nowrap;
      display: inline;
    }

    .recipient-label,
    .message-label,
    .cc-label {
      .ant-input {
        flex: unset;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .ant-modal-body {
      height: 300px;
    }

    .recipient-label,
    .message-label,
    .cc-label {
      flex-direction: column;

      .ant-input {
        margin: 0 !important;
      }
    }
  }
}

.mw-300 {
  width: 100%;
  max-width: 300px;
}

.breadcrumb {
  padding-bottom: 20px;
  padding-top: 20px;
  word-wrap: break-word;

  & > ol > li {
    display: flex;
  }

  &_mb-5 {
    margin-bottom: 5px;
  }
}

.ant-input--white-bg.ant-input,
.ant-input--white-bg .ant-input {
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
}

.ant-btn-type-default {
  background-color: transparent !important;

  span {
    color: red;
  }
}
.btn-link {
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    &:hover {
      color: white;
    }
  }
}
.no-data-message {
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.w-30-percent {
  width: 30%;
}

.w-50-percent {
  width: 50%;
}

.w-100-percent {
  width: 100%;
}

.la-world-icon {
  width: 100%;
  max-width: 35px;
  height: 32px;
  background-color: #19BFBF;

  display: inline-block;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.svg-icon {
  display: inline-flex;
  line-height: 1;
}
