.advertise-modal {
  max-width: calc(100% - 30px);

  &--image {
    width: 100%;
  }

  &--content {
    padding: 24px;
  }

  &--title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.85);
  }

  &--text {
    margin-bottom: 24px;
  }

  &--btn {
    height: 36px;
    padding: 4px 24px;
  }

  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 100px;
    background-color: #e3e3e3;
    color: #000;
  }
}
