.dlz-text {
  &--h1 {
    margin: 0;
    color: #004f57;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  &--h4 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }

  &--h5 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }
}

.dlz-btn {
  &.ant-btn-default {
    background-color: #ffffff;

    &:hover {
      background-color: rgba(255, 0, 0, 0.01);
    }

    span {
      color: #ff0000;
    }
  }
}

.dlz-page {
  &--divider {
    margin: 16px 0 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &--content {
    max-width: 848px;
  }
}

.dlz-section {
  position: relative;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &--header {
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &--title {
    margin-bottom: 16px;
  }

  &--body {
    padding: 12px;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.dlz--tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  background-color: #004f57;
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;
}
