.directory-subscriptions {
  .subscription-plans {
    .dlz-text--h1 {
      @media screen and (max-width: 991px) {
        font-size: 27px;
        line-height: 36px;
      }

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media screen and (max-width: 690px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &--page-wrapper {
      padding: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &--steps {
      padding: 0 0 16px;
    }

    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border-radius: 4px;
      background-color: #f7f7f7;
    }
  }

  .promo-code {
    height: min-content;
    padding: 12px 12px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &--info {
    &__label {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #8c8c8c;
    }

    &__value {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #0a150f;
    }
  }

  &--table {
    th {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
    td,
    a,
    a:hover {
      font-size: 14px;
      font-weight: 600;
      color: #0a150f;
    }
  }

  &--edit {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;
    color: #ff0000;
    line-height: 1;
    cursor: pointer;

    span {
      margin-right: 5px;
    }
  }

  &--skeleton {
    width: 100%;
    padding: 0 16px 16px;
  }

  &--skeleton-buttons {
    width: 100%;
    margin-top: 22px;

    display: flex;
    justify-content: space-between;
  }

  &--skeleton-button {
    width: 100% !important;

    &__small {
      width: 96px !important;
    }
  }

  &--options-wrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 16px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 2fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}

.subscription-benefit {
  display: flex;
  align-items: center;
  margin-top: 16px;

  svg {
    margin-right: 8px;
  }
}

.subscription-plans--modal {
  .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.85);
  }

  &__content {
    padding: 0 0 12px;
  }

  &__card-input-wrapper {
    width: 100%;
    box-sizing: border-box;
    min-height: 33px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 12px;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 10px 0;
  }

  &__request {
    color: #ff0000;
  }

  &__approved {
    color: #1ab394;
  }

  .CardField-number:after {
    content: "";
  }
}
