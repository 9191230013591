.social-auth {
  &--button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 4px 12px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;

    &__text {
      margin-left: 8px;
      font-weight: 600;
      font-size: 14px;
      color: #0A090B;
    }
  }
}
