$primary-content-bg: #F7F7F9;
$secondary-content-bg: #FFFFFF;

$primary-bg-color: #FF0000;

$primary-fg-color: #000000;
$secondary-fg-color: #717171;
$secondary-fg-color-01: #D8D8D8;
$secondary-fg-color-02: #E6E6E6;
$secondary-fg-color-03: #F7F7F7;

$dark-04: #242424;

$h1-color: #004F57;
$h2-color: #004F57;
$h3-color: #004F57;

$lg: 992px;
$md: 768px;
$sm: 576px;
$xl: 1200px;
$xs: 420px;

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
    background: $bgColor;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
