.social-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &--image {
    display: flex;
    font-size: 32px;
  }
}
